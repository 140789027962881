body {
    margin: 0;
    height: 100vh;
    background-color: #fff;
    color: #6c757d !important;
    font-size: 18px !important;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0rem;
    margin-left: 0rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.card {
    background-color: #ffffff;
    padding: 1rem 1.5rem;
    /* margin-bottom: 1rem; */
    margin: 0;
    border-radius: 12px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .05), 0px 1px 4px rgba(0, 0, 0, .08) !important;
    width: 100%;

}

.card-w-title {
    padding-bottom: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 1rem 0;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
    color: #212121;
}

h6 {
    font-size: 1rem;
}

li {
    font-size: 18px;
}

mark {
    background: #FFF8E1;
    padding: .25rem .4rem;
    border-radius: 12px;
    font-family: monospace;
}

blockquote {
    margin: 1rem 0;
    padding: 0 2rem;
    border-left: 4px solid #90A4AE;
}

hr {
    border-top: solid var(--surface-border);
    border-width: 1px 0 0 0;
    margin: 1rem 0;
}

p {
    margin: 0 0 1rem 0;
    line-height: 1.5;
    font-size: 18px;

}

.align-right {
    text-align: right;
}

.text-right {
    text-align: right;
}

.text-900 {
    color: #212121 !important;
}

.text-500 {
    color: #9e9e9e !important;
}

.block {
    display: block !important;
}




.p-menubar ul {
    margin-left: 100px;
}

.p-menubar .p-menuitem {
    margin: 0px 20px;
}

.p-menu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #212121;
    border: none;
    border-radius: 6px;
    width: auto;
}

.layout-menu li a.router-link-exact-active {
    font-weight: 700;
    color: var(--primary-color);
}

.p-menubar {
    color: #616161 !important;
}

.main-header {
    padding: 0 !important;
    margin-bottom: 20px;
}

.p-menubar ul {
    margin-left: 30px;
}

.p-input-icon-left {
    margin-right: 10px;
}

.p-menubar .p-menuitem {
    margin: 0px 2px;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
    color: #6c757d;
    font-size: 18px;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link {
    padding: 0.75rem;
}

.p-tieredmenu {
    width: auto;
}

.layout-main {
    margin-right: 0;
    margin-top: 0;
}

.md-sidebar {
    width: 15.6667%;
    padding-top: 0;

}

.mobSize {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    width: 84.3333%;
}

/* LoadingSpinner.css */
.login-container {
    position: fixed;
    width: 100%;
}

.loading-spinner-overlay {
    position: fixed;
    right: 0;
    margin-top: -15px;
    width: 84.333%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.301);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.loading-spinner-overlay-login {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

/* Additional styles for the ProgressSpinner if needed */
.p-progress-spinner {
    width: 50px;
    height: 50px;
}

.login-page {
    width: 50%;
    height: 550px;
    margin: auto;
    margin-top: 8%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.login-page .logo-container {
    background-color: #eff3f8;
    padding: 0 30px;
}

.logo-img {
    height: 71%;
    position: relative;
    display: flex;
    justify-content: center;
}

.grid-container {
    height: 100%;
}

.logo-img-mob {
    display: none;
}

.poweredby-img-btn {
    display: none;
}

.logo-img img {
    align-self: center;
}

.poweredby-img {
    text-align: center;
    vertical-align: middle;
}


.poweredby-img p {
    font-size: 14px;
    margin-top: 12px;
}

.login-page .login-details {
    background-color: #fff;
    padding: 0 30px;
    display: flex;
}

.login-box {
    align-self: center;
    width: 100%;
}

.login-page h1 {
    /* color: #3B82F6; */
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 54px;
    font-weight: 600;
}

.login-page .p-inputtext {
    width: 100%;
    margin: 20px 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}

.p-float-label>label {
    font-size: 16px;
}

.p-float-label input:focus~label,
.p-float-label input.p-filled~label,
.p-float-label textarea:focus~label,
.p-float-label textarea.p-filled~label,
.p-float-label .p-inputwrapper-focus~label,
.p-float-label .p-inputwrapper-filled~label {
    top: -5px;
    left: 0;
    font-size: 16px;
}

.login-page .signup-btn {
    text-align: center;
    margin-top: 55px;
}

.login-page .signup-btn .p-button {
    border-radius: 25px;
    width: 150px;
}

/* Header_topbar css start */

.Header_topbar {
    position: sticky;
    height: 4.5rem;
    z-index: 997;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0 1rem;
    background-color: var(--surface-card);
    transition: left .2s;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 5px rgb(0 0 0 / 2%), 0 0 2px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 8%);
}

.Property_name {
    align-self: center;
}

.Header_topbar .logo_span {
    text-align: center;
    width: 270px;
}

.Header_topbar .header_logo {
    height: 65px;
}

.Header_topbar .p-menubar {
    border: none;
    width: 100%;
    background: #fff;
    padding: 0 0.5rem 0 0;
}

.main-header .p-menubar {
    background: #fff;
}

.togglebtn {
    position: absolute;
    top: 9px;
    left: 8rem;
    z-index: 1111;
}

.Sidebar_togle {
    position: absolute;
    width: 91.5%;
    top: 100%;
    background: #fff;
    padding: 5px;
}

.Sidebar_togle .p-panelmenu-header>a {
    padding: 0.6rem;
}

.sidebar_toggle {
    position: relative;
}

.main-header li.active {
    background: #80808014;
    width: 95%;
    margin: auto;
    border-radius: 5px;
}

.Header_topbar .user_icon {
    border-radius: 50%;
    border: 1px solid #6c757d;
    padding: 8px;
    margin-left: 10px;
    width: 36px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
}

.Header_topbar .user_icon:hover {
    background: #3B82F6;
    color: #ffffff;
    border-color: #3B82F6;
}

/* .Header_topbar .login_option a {
    color: #495057;
    text-decoration: none;
} */
/* .profile-overlay .p-overlaypanel-content {
    padding: 0;
}
.profile-overlay .shadow-profile li {
    list-style: none;
    margin: 0;
    font-size: 5px;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 5px;

}
.profile-overlay .shadow-profile li:hover {
    border: 1px solid #3b82f645;
    background-color: #3b82f614 !important;
}

.profile-overlay .shadow-profile li .pi-sign-out {
    font-size: 12px;
    float: right;
    margin-top: 3px;
} */

.Header_topbar .notification .notification_icon {
    border-radius: 50%;
    border: 1px solid #6c757d;
    padding: 8px;
    margin-left: 10px;
    position: relative;
    margin-right: 8px;
    width: 36px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
}

.Header_topbar .notification .notification_icon:hover {
    background: #3B82F6;
    color: #ffffff;
    border-color: #3B82F6;
}

.Header_topbar .notification .notification_icon .p-badge-no-gutter {
    position: absolute;
    top: -6px;
    width: 22px;
    height: 22px;
    font-size: 10px;
}

.Header_topbar .header_start {
    display: flex;
}

.Header_topbar .propertyName {
    position: relative;
    display: flex;
    color: #343a40;
    font-size: 18px;
    border-left: 1.5px solid #3b82f6bf;
    padding: 5px 13px;
}

.Header_topbar .toggle_btn_top {
    background: #fff;
    color: #212121;
    border: 1px solid #6c757d80;
    padding: 10px;
    border-radius: 3px;
    width: 36px;
    height: 36px;
    margin-top: 0;
    margin-right: 14px;
}

.Header_topbar .logout_btn {
    background: #fff;
    color: #212121;
    border: 1px solid #6c757d;
    padding: 10px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    margin-top: 0;
    margin-left: 10px;
}

.Filter_overlay {
    width: 360px;
}

.Filter_overlay .p-dropdown {
    width: 100% !important;
}

.Filter_overlay .p-overlaypanel-content {
    padding: 0.5rem;
}

.Filter_overlay .p-overlaypanel-close {
    z-index: 1;
}

.sidebar_toggle {
    display: none;
}

.li_divider {
    overflow: hidden;
    border-top: 1px solid #d9dee3;
}

.p-overlaypanel {
    box-shadow: 0 0.25rem 1rem rgb(161 172 184 / 45%);
}

.profile_container .user_prof {
    font-size: 14px;
    margin-right: 0.5rem;
    font-weight: 600;
    color: #3B82F6;
    align-self: center;
}

.profile_container .optn_prof {
    font-size: 16px;
    margin-right: 0.5rem;
    font-weight: 500;
    color: #697a8d;
}

.profile_container {
    padding-left: 0;
    min-width: 160px;
}

.profile_container a {
    color: #495057;
    text-decoration: none;
    padding: 0.532rem 1.25rem;
}

.shadow-profile .profile_container li:hover {
    color: #5f6e7f;
    background-color: rgba(67, 89, 113, .04);
}

.profile_container .d-flex {
    display: flex;
    justify-content: center;
}

.avatar {
    position: relative;
    width: 41px;
    height: 41px;
    cursor: pointer;
}

.profile_container .profile-circle {
    width: 40px;
}

.profile_container .post_status {
    color: #a1acb8;
    font-size: 12px;
}

.profile_container .name {
    color: #697a8d;
    font-size: 14px;
    font-weight: 600;
}

.profile_container .avatar.avatar-online:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 3px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    box-shadow: 0 0 0 2px #fff;
    background-color: #71dd37;
}


@media screen and (max-width: 768px) {
    .Sidebar_left {
        display: none;
    }

    .hide_btn {
        display: none;
    }

    .sidebar_toggle {
        display: block;
    }
}

/* Header_topbar css end */

.col_12_mt {
    margin-top: -8px;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.action_btns {
    display: flex;
    justify-content: end;
}

.layout-wrapper {
    height: 100vh;
    background-color: #eff3f8;
}

.mobSize {
    background-color: #eff3f8;
}

.container_box {
    padding: 1rem;
    /* margin-top: -15px; */
}

.action_btns .p-button {
    padding: 6px 0.8rem !important;
    font-size: 13px !important;
    background: #fff;
    border-radius: 30px !important;
    color: #12b81b;
    border-color: transparent;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.action_btns .p-button .p-button-icon-right {
    font-size: 14px;
}

.action_btns .p-button:hover {
    background: #12b81b;
    border-color: #12b81b;
}

.p-datatable table {
    overflow: auto;
}

.status {
    text-align: center;
}

.status .not-submitted {
    background: #f4a626;
    padding: 4px 12px;
    color: #fff;
    font-weight: 600;
    border-radius: 16px;
    font-size: 13px;
    width: fit-content;
    margin: auto;
}

.status .submitted {
    background: #47c022;
    padding: 4px 12px;
    color: #fff;
    font-weight: 600;
    border-radius: 16px;
    font-size: 13px;
    width: fit-content;
    margin: auto;
}

.status .processed {
    background: #11bc50;
    padding: 4px 12px;
    color: #fff;
    font-weight: 600;
    border-radius: 16px;
    font-size: 13px;
    width: fit-content;
    margin: auto;
}

.page_title {
    border-bottom: 1px solid #80808054;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.reload_btn {
    width: 2.5rem !important;
    padding: 9px !important;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background: #fff;
    background-color: transparent;
    color: #06b6d4;
    border-color: transparent;
}

/* Sidebar_right css start */

.Sidebar_right {
    /* width: 30rem; */
    width: 45% !important;
    height: 87.3% !important;
    margin-top: 19px;
    position: relative;
}

.Sidebar_right .header_top {
    position: absolute;
    top: 5px;
    left: 19px;
    width: 95%;
}

.Sidebar_right .header_top p {
    border-bottom: 1px solid #3B82F675;
}

.Sidebar_right .p-inputtext {
    margin: 0;
    padding: 4px 8px;
    border-radius: 2px;
    width: 100%;
    font-size: 14px;
    height: 30px;
}

.Sidebar_right .p-dropdown {
    border-radius: 2px;
    width: 100%;
    height: 30px;
}

.Sidebar_right .p-calendar {
    border-radius: 2px;
    width: 100%;
    height: 30px;
}

.Sidebar_right .p-calendar .p-button.p-button-icon-only {
    padding: 4px 8px;
}

.toggle_status .p-button-icon-left {
    margin-right: 0;
    font-size: 14px;
}

.toggle_status.p-button {
    font-size: 14px;
    padding: 6px 8px;
    width: 100px;
    background: #80808036;
}

.toggle_status.p-button.p-highlight {
    background: #129105bd;
    border-color: #129105bd;
    color: #ffffff;
}

.Sidebar_right .p-sidebar-header {
    padding: 1.25rem;
    z-index: 1;
}

.Sidebar_right .add_divider {
    border-bottom: 1px solid #3B82F675;
}

.Sidebar_right .top_grid {
    background: #8080800f;
    padding: 10px;
    margin-top: 0;
}

.Sidebar_right .p-sidebar-content {
    overflow-y: hidden !important;
}

.Sidebar_right .sroll_body {
    height: 75vh;
    overflow-y: auto;
}

.Sidebar_right .footer_content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
}

.Sidebar_right .submit_box .p-button {
    border-radius: 3px;
    padding: 0.5rem 1.5rem;
    float: right;
    margin-right: 5%;
}

@media screen and (max-width: 1600px) {
    .Sidebar_right .sroll_body {
        height: 69vh;
        overflow-y: auto;
    }
}

@media screen and (max-width: 1320px) {
    .Sidebar_right {
        width: 50% !important;
    }
}


/* Sidebar_right css end */

/* Sidebar_left css start */
.Sidebar_left {
    position: fixed;
    width: 15.6667%;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 999;
    overflow-y: auto;
    transition: transform .2s, left .2s;
    background-color: var(--surface-overlay);
    padding: 0.5rem 1rem;
    box-shadow: 0 3px 5px rgb(0 0 0 / 2%), 0 0 2px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 8%);
}

.Sidebar_left ul li {
    align-items: center;
    position: relative;
    outline: 0 none;
    color: var(--text-color);
    cursor: pointer;
    border-radius: 0.375rem;
    font-size: 0.857rem;
    transition: background-color .2s, box-shadow .2s;
}

.Sidebar_left ul li .p-menuitem-icon {
    width: 1.28571429em;
    text-align: center;
}

.Sidebar_left .p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon {
    position: absolute;
    right: 5px;
    font-size: 12px;
}

.Sidebar_left .p-panelmenu-header>a {
    padding: 0.75rem 1rem;
    border: none;
    font-size: 14px;
    /* text-transform: uppercase; */
    font-weight: 700;
    color: #495057;
    margin: 2px 0;
    background: #fff;
}

.Sidebar_left .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
    background: var(--surface-hover);
    border-color: var(--surface-hover);
    color: #343a40;
    color: #3B82F6;
}

.Sidebar_left .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: var(--surface-hover);
    border-color: var(--surface-hover);
    color: #3B82F6 !important;
}

.Sidebar_left .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #3B82F6;
}

.Sidebar_left .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #3B82F6;
}

.Sidebar_left .p-panelmenu-content {
    border: none;
    padding: 0;
}

.Sidebar_left .p-panelmenu-header.p-highlight>a {
    /* border: 1px solid #3B82F675; */
    border-radius: 0.375rem;
}

.Sidebar_left .p-panelmenu-header>a:focus {
    box-shadow: none;
}

.Sidebar_left .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
    box-shadow: none;
}

.Sidebar_left .Active .p-panelmenu-header>a {
    color: #3B82F6;
    background-color: #3b82f626;
    border-radius: 0.375rem !important;
}

.Sidebar_left .Active .p-panelmenu-header>a:hover {
    color: #3B82F6 !important;
    background-color: #3b82f626 !important;
    border-radius: 0.375rem !important;
}

/* .Sidebar_left .Active .p-panelmenu-header>a .p-menuitem-icon {
    color: #3B82F6;
} */

.Sidebar_left .p-panelmenu-header.p-highlight:not(.p-disabled)>a:hover {
    color: #3B82F6;
    background: var(--surface-hover);
    border-color: var(--surface-hover);
    border-radius: 0.375rem !important;
}

.Sidebar_left .Active .p-menuitem-link {
    color: #3B82F6 !important;
    font-weight: 600;
    background-color: rgba(105, 108, 255, .16);
    border-radius: 0.375rem !important;
    width: 95%;
}

.Sidebar_left .Active .p-menuitem-link:hover {
    color: #3B82F6;
    background-color: rgba(105, 108, 255, .16) !important;
}

.Sidebar_left .Active .p-menuitem-link .p-menuitem-icon {
    color: #3B82F6 !important;
}

.Sidebar_left .Active .p-menuitem-link .p-menuitem-text {
    color: #3B82F6 !important;
}

.Sidebar_left .p-panelmenu-header>a .p-panelmenu-icon {
    position: absolute;
    right: 0;
    font-size: 12px;
}

.Sidebar_left .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
    position: absolute;
    right: 0;
    font-size: 12px;
}

.Sidebar_left .p-panelmenu .p-panelmenu-content {
    margin-bottom: 0 !important;
}

.Sidebar_left .p-toggleable-content {
    display: none !important;
}

.Sidebar_left .Active::before {
    content: "";
    position: absolute;
    right: 0;
    width: 0.25rem;
    height: 2.5rem;
    border-radius: 0.375rem 0 0 0.375rem;
    background: #3B82F6 !important;
}

.pi-home {
    color: rgb(94, 114, 228);
}

.pi-comments {
    color: rgb(251, 99, 64);
}

.pi-reply {
    color: rgb(45, 206, 137) !important;
}

.pi-sliders-h {
    color: rgb(17, 205, 239) !important;
}

.pi-phone {
    color: rgb(245, 54, 92) !important;
}

.pi-users {
    color: rgb(17, 205, 239) !important;
}

.pi-briefcase {
    color: rgb(251, 99, 64) !important;
}

@media screen and (max-width: 1400px) {
    .md-sidebar {
        min-width: 220px;
    }

    .Sidebar_left {
        width: 220px;
    }

    .responsiveSize {
        width: calc(100% - 220px);
        margin-left: auto;
    }

    .loading-spinner-overlay {
        width: calc(100% - 220px);
    }

    .login-page {
        width: 60%;
        height: 500px;
    }
}

@media screen and (max-width: 768px) {
    .mobSize {
        padding: 0;
        margin-top: -8px;
    }

    .top_icons_list {
        display: flex;
    }

    .loading-spinner-overlay {
        width: 100%;
    }

    .Header_topbar {
        z-index: 1105;
    }

    .Sidebar_togle {
        width: calc(100% - 31px);
        left: 1rem;
        padding: 10px 16px;
        border: 0 none;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .Sidebar_togle .p-toggleable-content {
        display: none;
    }

    .Sidebar_togle .p-panelmenu-header>a {
        background: #fff;
        border: 1px solid #ededed;
        border-radius: 0px;
    }

    .Sidebar_togle .p-panelmenu-header.p-highlight>a {
        background: #f6f9fc;
        color: #3B82F6;
        border-color: #2196f31f;
    }

    .Sidebar_togle .p-panelmenu-header.p-highlight:not(.p-disabled)>a:hover {
        background: #f6f9fc;
        color: #3B82F6;
        border-color: #2196f31f;
    }

    .Sidebar_togle .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
        background: #f6f9fc;
        color: #3B82F6;
        border-color: #2196f31f;
    }
}


/* Sidebar_left css end */

/* datatable css start */

.p-datatable .p-datatable-thead>tr>th {
    font-size: 14px;
    padding: 0.6rem 1rem;
}

.p-datatable .p-datatable-tbody>tr>td {
    padding: 0.4rem 1rem;
    font-size: 14px;
}

.p-datatable .p-inputtext {
    padding: 7px 2.5rem;
}

.p-datatable .p-button {
    padding: 8px 1rem;
    font-size: 14px;
    border-radius: 3px;
}

.p-calendar {
    margin-right: 10px;
}

.p-calendar .p-inputtext {
    width: 170px;
}

.p-datatable .edit_btn {
    background: #129105bd;
    border: 1px solid #129105bd;
    border-radius: 3px;
    padding: 4px 1rem;
}

.print_btn {
    width: 2.5rem !important;
    padding: 9px !important;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background: #fff;
    background-color: transparent;
    color: #54b447;
    border-color: transparent;
    margin-right: 10px;
}

.print_btn:hover {
    background: #34a026 !important;
    border-color: #54b447 !important;
}

.p-datatable .edit_btn:hover {
    background: #127c07;
    border: 1px solid #169209;
}

.p-datatable .del_btn {
    background: #ef0c0c;
    border: 1px solid #ef0c0c;
}

.p-datatable .del_btn:hover {
    background: #d40505;
    border: 1px solid #ef0c0c;
}

.p-datatable .p-datatable-header {
    background: #fff;
    border: none;
}

.p-datatable .p-datatable-header {
    padding: 0;
    padding-bottom: 10px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2.6rem;
    height: 2.6rem;
    font-size: 14px;
}

.p-paginator {
    border: none;
}

.p-paginator .p-dropdown {
    height: 35px;
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding: 0.75rem 0.75rem;
    padding-top: 8px;
}

.p-dropdown .p-dropdown-trigger {
    width: 2rem;
}

.p-datatable-wrapper {
    overflow: auto;
    /* max-height: 56vh; */
    max-height: calc(100vh - 360px);
}
.p-column-title{
    width: 100%;
}

.checkbox-container {
    display: flex;
    justify-content: space-between;
}

.checkbox-container .title {
    align-self: center;
}
.checkIns_table thead th:nth-child(1){
    width: 60px;
}
.checkIns_table thead th:nth-child(2){
    width: 160px;
}
.checkIns_table thead th:nth-child(4){
    width: 160px;
}
.checkIns_table thead th:nth-child(5){
    width: 160px;
}
.checkIns_table thead th:nth-child(6){
    width: 160px;
}
.checkIns_table thead th:nth-child(7){
    width: 80px;
}



/* datatable css end */

/* Dashboard css start */

.cardfontsize {
    font-size: 14px;
}

.dashboard .p-datatable .p-datatable-thead>tr>th {
    font-size: 14px;
    padding: 0.7rem 1rem;
}

.dashboard .p-datatable .p-datatable-tbody>tr>td {
    padding: 1rem;
    font-size: 14px;
    padding: 0.6rem 1rem;
}

.dashboard .p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2.6rem;
    height: 2.6rem;
    font-size: 14px;
}

.checkin_container {
    display: flex;
}

.checkin_List {
    width: 70%;
}

.details_card {
    border: 1px solid #8080801c;
    padding: 0;
    border-radius: 6px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04);
    height: 100%;
}
.details_card2 {
    border: 1px solid #8080801c;
    padding: 0;
    border-radius: 6px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04);
    min-height: 135px;
}
.details_card3 {
    border: 1px solid #8080801c;
    padding: 0;
    border-radius: 6px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04);
}

.checkin_details {
    width: 30%;
    margin-left: 15px;
}

.Details_title {
    padding: 12px 20px;
    background-color: #f8f9fa;
}

.Details_title h5 {
    margin: 0;
    font-size: 16px;
    letter-spacing: 0.5px;
}

.Details_desc_box {
    padding: 8px 20px;
}

.Details_desc_box ul {
    padding-left: 0;
    margin: 0;
}

.Details_desc {
    color: #495057;
    display: flex;
    font-size: 15px;
    margin: 8px 0;
}

.Details_desc span {
    align-self: center;
    width: calc(100% - 160px);
    word-wrap: break-word;
}

.Details_desc .not-submitted {
    background: #f4a626;
    padding: 4px 12px;
    color: #fff;
    font-weight: 600;
    border-radius: 16px;
    font-size: 13px;
    width: fit-content;
}

.Details_desc .submitted {
    background: #47c022;
    padding: 4px 12px;
    color: #fff;
    font-weight: 600;
    border-radius: 16px;
    font-size: 13px;
    width: fit-content;
}

.Details_desc .Total_seperator {
    border-top: 1px solid gray;
}

.Details_desc_box p {
    font-size: 15px;
    margin: 0;
    align-self: center;
    width: 160px;
}

.Details_desc .p-inputswitch .p-inputswitch-slider {
    height: 25px;
    width: 45px;
    top: 4px;
    margin-left: 10px;
}

.Details_desc .p-inputswitch .p-inputswitch-slider:before {
    width: 16px;
    height: 16px;
    margin-top: -8px;

}

/* Dashboard css start */

@media screen and (max-width: 1219px) {
    .p-input-icon-left {
        width: 36%;
    }

    .p-datatable .p-inputtext {
        width: 100%;
    }

    .p-calendar .p-inputtext {
        width: 100px;
        padding: 7px 10px;
    }

    .login-page {
        width: 60%;
        height: 400px;
    }

    .logo-img img {
        height: 100px;
    }

    .poweredby-img img {
        height: 30px;
    }

    .login-page .p-inputtext {
        margin: 14px 0;
    }

    .p-float-label input:focus~label,
    .p-float-label input.p-filled~label,
    .p-float-label textarea:focus~label,
    .p-float-label textarea.p-filled~label,
    .p-float-label .p-inputwrapper-focus~label,
    .p-float-label .p-inputwrapper-filled~label {
        top: 5px;
        left: 0;
        font-size: 14px;
    }

    .login-page .signup-btn {
        margin-top: 15px;
    }

    .login-page h1 {
        font-size: 22px;
        margin-bottom: 40px;
    }

    .login-page .signup-btn .p-button {
        width: 120px;
        font-size: 14px;
    }

    .poweredby-img p {
        font-size: 12px;
    }
}

@media screen and (max-width: 960px) {
    .Header_topbar .p-menubar-button {
        display: none;
    }

    .p-input-icon-left {
        width: 30%;
    }

    .p-calendar .p-inputtext {
        width: 87px;
        padding: 7px 5px;
    }

    .p-datatable-tbody {
        border: 1px solid #dee2e6;
    }

    .login-page {
        width: 72%;
    }
}

@media screen and (max-width: 860px) {
    .p-datatable-header .flex-column {
        display: block !important;
    }

    .p-input-icon-left {
        width: 100%;
    }

    .p-datatable-header .p-input-icon-left {
        width: 100%;
        margin-bottom: 10px;
    }

    .p-datatable-header .view_box {
        text-align: end;
    }

    .p-datatable-wrapper {
        max-height: calc(100vh - 400px);
    }

}

@media screen and (max-width: 768px) {
    .Sidebar_left {
        position: fixed;
        width: 93.5%;
        height: auto;
    }

    .mobSize {
        width: 100%;
        flex: 0 0 auto;
        padding: 0.5rem;
    }

    .responsiveSize {
        padding: 0;
    }

    .function_btn_box {
        display: flex;
    }

    .Header_topbar .notification .notification_icon {
        margin-left: 5px;
        margin-right: 5px;
    }

    .Header_topbar .logout_btn {
        margin-left: 5px;
    }

    .login-page {
        width: 60%;
        height: auto;
        margin-top: 15%;
    }

    .login-page .logo-container {
        display: none;
    }

    .login-box {
        align-self: auto;
        padding: 20px 5px;
    }

    .logo-img-mob {
        display: block;
        text-align: center;

    }

    .login-page h1 {
        font-size: 20px;
        margin-bottom: 30px;
        margin-top: 40px;
    }

    .logo-img-mob img {
        height: 100px;
    }

    .login-page .signup-btn {
        margin-top: 30px;
        margin-bottom: 50px;
    }

    .poweredby-img-btn {
        display: block;
        text-align: center;

    }

    .poweredby-img-btn img {
        height: 30px;

    }

    .poweredby-img-btn p {
        font-size: 14px;
    }
}

@media screen and (max-width: 650px) {
    .login-page {
        width: 70%;
    }
}

@media screen and (max-width: 500px) {
    .login-page {
        width: 90%;
    }
}

@media screen and (max-width: 470px) {
    .page_title {
        margin-bottom: 0px;
    }

    .p-datatable-wrapper {
        max-height: calc(100vh - 420px);
    }
}


/* // Clock Loader Css Start  // */

.clock {
    border-radius: 70px;
    border: 3px solid #03A9F4;
    position: absolute;
    top: 35%;
    display: block;
    width: 120px;
    height: 120px;
}

.clock:after {
    content: "";
    position: absolute;
    background-color: #03A9F4;
    top: 13px;
    left: 48%;
    height: 50px;
    width: 4px;
    border-radius: 5px;
    -webkit-transform-origin: 50% 97%;
    transform-origin: 50% 97%;
    -webkit-animation: grdAiguille 2s linear infinite;
    animation: grdAiguille 2s linear infinite;
}

@-webkit-keyframes grdAiguille {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes grdAiguille {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.clock:before {
    content: "";
    position: absolute;
    background-color: #03A9F4;
    top: 24px;
    left: 48%;
    height: 40px;
    width: 4px;
    border-radius: 5px;
    -webkit-transform-origin: 50% 94%;
    transform-origin: 50% 94%;
    -webkit-animation: ptAiguille 12s linear infinite;
    animation: ptAiguille 12s linear infinite;
}

@-webkit-keyframes ptAiguille {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes ptAiguille {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* // Clock Loader Css End  // */

.payment_container{
    width: 50%;
}
.Payment_desc_box{
    font-size: 15px;
    padding: 8px 20px;
    color: #495057;
    line-height: 2.2;
}
.Payment_desc_box p {
    margin-bottom: 0;
    line-height: 2.2;
    font-size: 15px;
}
.Payment_desc_box .border-bottom{
    border-bottom: 1px solid;
}

.Payment_desc_box .Title {
    width: calc(100% - 300px);
}
.Payment_desc_box .Title2 {
    width: calc(100% - 450px);
}
.Payment_desc_box .row{
    display: flex;
    justify-content: space-between;
}
.Payment_desc_box .Ammonunt_section{
    display: inline-flex;
}
.Payment_desc_box .Ammonunt{
    width: 130px;
    padding-right: 20px;
    text-align: right;
}
.Payment_desc_box .Ammonunt2 {
    width: 130px;
    padding: 0 10px;
    text-align: right;
}
.Payment_desc_box .Ammonunt3 {
    width: 260px;
    text-align: right;
}
.Payment_desc_box .Ammonunt3 p{
    padding: 0 20px;
}
.Payment_desc_box .Ammonunt_status{
    width: 130px;
    text-align: center;
    padding-left: 20px;
}
.Payment_desc_box .Ammonunt_section2 {
    width: 390px;
    display: inline-flex;
}
.Payment_desc_box .Ammonunt_section{
    width: 260px;
}
.totalpay_border{
    border-bottom: 1px solid;
    padding: 0 !important;
}
.totalpay_border .payment_block{
    width: 100%;
    display: block;
    margin-bottom: 2px;
    padding: 0 20px;
    border-bottom: 1px solid;
}
.details_card2_conatiner{
    width: calc(100% - 740px);
}
.details_card3_conatiner{
    width: 740px;
}
.Communications_Details .toggle_btn{
    display: flex;
    justify-content: center;
}
.mt-30{
    margin-top: -25px;
}
.p-sidebar-mask.p-component-overlay{
    z-index: 998 !important;
    top: 4.5rem;
    right: 0;
    left: auto;
    background-color: #eff3f8;
}
.checkins_details_panel.checkins_panel{
    width: 84.3333%;
}
.checkins_details_panel.checkins_panel_mt12{
    width: 100%;
}

.checkins_details_panel{
    background-color: #eff3f8;
}
.checkins_details_panel .p-sidebar-header {
    display: none;
}
.checkins_details_panel .pi-thumbs-up-fill{
    color: #11bc50;
    align-self: center;
}
.checkins_details_panel .pi-thumbs-down-fill{
    color: #fb6340;
    align-self: center;
}

@media screen and (max-width: 1400px) {
    .p-sidebar-mask.p-component-overlay{
        right: 0;
        justify-content: right;
        left: unset;
    }
    .checkins_details_panel.checkins_panel{
        width: calc(100% - 220px);
    }
    
}
@media screen and (max-width: 1370px) {
.details_card2_conatiner{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.details_card2{
    width: 49%;
}
.details_card2.mt-5{
    margin-top: 0 !important;
}
.details_card3_conatiner{
    width: 100%;
}
.Communications_Details {
    width: 50%;
}
.Reservation_Details {
    width: 50%;
}
.Guest_Details {
    width: 100%;
}
}

@media screen and (max-width: 768px) {
    .details_card2_conatiner{
        width: 100%;
        display: block;
    }
    .Communications_Details {
        width: 100%;
    }
    .Reservation_Details {
        width: 100%;
    }
    .details_card2{
        width: 100%;
    }
    .details_card2.mt-5{
        margin-top: 16px !important;
    }
    .checkins_details_panel.checkins_panel{
        width: 100%;
    }
    }